const carouselSelector = '.cmp-carousel'
const currentItemWrapper = '.cmp-carousel__current-item'
const activeIndicator = '.cmp-carousel__indicator--active'

const updateCurrentItemWrapper = (carouselCurrentItemWrapper, currentItem, numberOfListItems) => {
  carouselCurrentItemWrapper.innerText = `${currentItem}/${numberOfListItems}`
}

const initCurrentItemCarousel = (carousel, carouselCurrentItemWrapper) => {
  const numberOfListItems = +carousel.dataset.carouselItems
  const nextArrow = carousel.querySelector('.cmp-carousel__action--next')
  const previousArrow = carousel.querySelector('.cmp-carousel__action--previous')
  let currentItem = +carousel.querySelector(activeIndicator)?.dataset.indicatorid || 1

  if (nextArrow) {
    nextArrow.addEventListener('click', () => {
      currentItem = currentItem + 1 > numberOfListItems ? 1 : currentItem + 1
      updateCurrentItemWrapper(carouselCurrentItemWrapper, currentItem, numberOfListItems)
    })
  }

  if (previousArrow) {
    previousArrow.addEventListener('click', () => {
      currentItem = currentItem - 1 < 1 ? numberOfListItems : currentItem - 1
      updateCurrentItemWrapper(carouselCurrentItemWrapper, currentItem, numberOfListItems)
    })
  }
}

const initCarousel = carousel => {
  const carouselCurrentItemWrapper = carousel.querySelector(currentItemWrapper)

  if (carouselCurrentItemWrapper) {
    carouselCurrentItemWrapper.classList.toggle('cmp-carousel__current-item--hidden', carousel.length <= 3)
    initCurrentItemCarousel(carousel, carouselCurrentItemWrapper)
  }
}

const carousels = document.querySelectorAll(carouselSelector)

if (carousels?.length) {
  carousels.forEach(initCarousel)
}
