import debounce from '@site/js/utils/debounce'

;(function () {
  'use strict'

  function onDocumentReady() {
    const teasersWithStage = document.querySelectorAll('.cmp-teasers-stage')

    if (!teasersWithStage.length) {
      return
    }

    teasersWithStage.forEach(teaserWithStage => {
      const navigation = teaserWithStage.querySelector('.cmp-teasers-stage__navigation')
      const counter = navigation?.querySelector('.cmp-teasers-stage__navigation-teaser-counter')
      const previousButton = navigation?.querySelector('.cmp-teasers-stage__navigation-button--previous')
      const nextButton = navigation?.querySelector('.cmp-teasers-stage__navigation-button--next')
      const teasersContainer = teaserWithStage.querySelector('.cmp-teasers-stage__teasers')
      const teasers = [...teaserWithStage.querySelectorAll('.cmp-teasers-stage__teaser')]

      if (!navigation || !teasersContainer || teasers.length === 0) {
        return
      }

      let currentIndex = 0
      let totalTeasers = teasers.length

      function getBlockWidth() {
        const gap = 16
        return teasers[0].offsetWidth + gap
      }

      function updateTeaserPosition() {
        const blockWidth = getBlockWidth()
        const offset = -(currentIndex * blockWidth)
        teasersContainer.style.transform = `translateX(${offset}px)`

        previousButton.disabled = currentIndex === 0
        nextButton.disabled = currentIndex >= totalTeasers - 1
        counter.textContent = `${currentIndex + 1}/${totalTeasers}`
      }

      previousButton.addEventListener('click', () => {
        if (currentIndex > 0) {
          currentIndex--
          updateTeaserPosition()
        }
      })

      nextButton.addEventListener('click', () => {
        if (currentIndex < totalTeasers - 1) {
          currentIndex++
          updateTeaserPosition()
        }
      })
      updateTeaserPosition()

      // Recalculate on resize
      window.addEventListener(
        'resize',
        debounce(() => {
          updateTeaserPosition()
        }, 200),
      )
    })
  }

  if (document.readyState !== 'loading') {
    onDocumentReady()
  } else {
    document.addEventListener('DOMContentLoaded', onDocumentReady)
  }
})()
