const getLocationHash = () => {
  return location.hash
}

const setActiveTab = () => {
  const activeTabWithMediaGallery = document.querySelector('.cmp-tabs__tabpanel--active .mediagallery:not(.custom-event-fired)')

  if (activeTabWithMediaGallery) {
    const changeTabEvent = new CustomEvent('changetab')
    window.dispatchEvent(changeTabEvent)
    activeTabWithMediaGallery.classList.add('custom-event-fired')
  }

  const TabsVertical = document.querySelector('.cmp-tabs.cmp-tabs-vertical')
  const TabsHorizontal = document.querySelector('.cmp-tabs:not(.cmp-tabs-vertical)')
  const header = document.querySelector('.cmp-header')

  if (TabsVertical) {
    const currentHash = window.location.hash
    setTimeout(() => {
      history.replaceState(null, null, ' ')

    const headerHeight = header ? header.offsetHeight : 0

    let tabPosition
    if (TabsVertical?.querySelector(currentHash)) {
      tabPosition = TabsVertical.getBoundingClientRect().top + window.scrollY
    } else if (TabsHorizontal && TabsHorizontal.querySelector(currentHash)) {
      tabPosition = TabsHorizontal.getBoundingClientRect().top + window.scrollY
    }

    if (tabPosition !== undefined) {
      window.scrollTo({
        top: tabPosition - headerHeight - 10,
        behavior: "smooth",
      });
    }

    setTimeout(() => history.replaceState(null, null, currentHash), 150)
  }, 200)
}}

const hasHorizontalScroll = (element) => element.scrollWidth > element.clientWidth;

document.addEventListener('DOMContentLoaded', () => {
  const tabs = Array.from(document.querySelectorAll('.cmp-tabs__tab'))
  const locationHash = getLocationHash()

  const TabsVertical = document.querySelector('.cmp-tabs.cmp-tabs-vertical')
  const ActiveTabHeading = TabsVertical?.querySelector('.cmp-tab-active--heading')
  const TabsContainer = TabsVertical?.querySelector('.cmp-tabs__tablist')
  const TabsList = document.querySelector('.cmp-tabs__tablist')

  if (TabsContainer) {
    const FirstTab = TabsContainer.querySelector('.cmp-tabs__tab')
    FirstTab.appendChild(document.createElement('span'))
    const tabsExpanded = 'cmp-tabs__tab--expanded'

    const ActiveTabText = TabsContainer.querySelector('.cmp-tabs__tab--active').innerHTML
    ActiveTabHeading.innerHTML = ActiveTabText

    ActiveTabHeading.addEventListener('click', () => {
      TabsContainer.classList.add(tabsExpanded)
    })

    const FirstTabSpan = FirstTab.querySelector('span')
    FirstTabSpan.addEventListener('click', e => {
      TabsContainer.classList.remove(tabsExpanded)
      e.stopPropagation()
    })

    if (tabs.length) {
      tabs.forEach(el => {
        const elementId = `#${el.id}`
        el.addEventListener('click', e => {
          e.stopPropagation()

          const TabElgrandparent = e.target.closest('.cmp-tabs-vertical')
          if (TabElgrandparent) {
            ActiveTabHeading.innerHTML = el.innerHTML
            TabsContainer.classList.remove(tabsExpanded)
            return false
          }

          const isVerticalTab = el.closest('.cmp-tabs-vertical') !== null
          el.dataset.tabType = isVerticalTab ? 'vertical' : 'horizontal'

          setActiveTab()
        });

        if (locationHash && elementId === locationHash) {
          setActiveTab()
        }
      })
    }

    if (hasHorizontalScroll(TabsList)) {
      TabsList.classList.add('cmp-tabs__tablist--has-scroll')
    }
  }

  if (locationHash) {
    setActiveTab();
  }
})
