import { breakpointValues, isMobileHeader } from '@site/js/utils/breakpoint'
import debounce from '@site/js/utils/debounce'
import { trackEvent } from '@site/js/utils/tracking'

import initLogoLinkTracking from './header-logo-tracking.js'
import * as headerConstants from './headerConstants'

/**
 * Header Component
 */
const Header = () => {
  const {
    header,
    overlay,
    navigationWrapper,
    toggleMenuButton,
    closeDropdownButtons,
    toggleDropdownButtons,
    search,
    searchItems,
    sticky,
    navigation,
    buttonBack,
    visibleOverlayClassName,
    stickyHeaderClassName,
    dropdownClassName,
    dropdownContentSelector,
    noScrollClassName,
    openMenuClassName,
    openDropdownClassName,
    fixedDropdownClassName,
    menuScrolled,
    activeItemSelector,
    ariaExpanded,
    logoutButtonClassName,
    navigationItemsSelector,
    navigationTitleClassName,
    ANCHOR_TAG,
  } = headerConstants
  const navigationTopPosition = navigationWrapper && navigationWrapper.offsetTop

  /**
   * On page scroll toggles classes to show / hide the sticky navigation
   * and adds additional space below the header to avoid change of the page scroll size
   */
  const toggleStikyHeader = () => {
    if (window.scrollY <= navigationTopPosition) {
      header.style.paddingBottom = 0
      header.classList.remove(stickyHeaderClassName)
    } else {
      header.classList.add(stickyHeaderClassName)
    }
  }

  /**
   * Finds header dropdown container
   * @return {HTMLElement} dropdown container
   * */
  const getDropdown = dropdownButton => {
    return dropdownButton.closest(`.${dropdownClassName}`)
  }

  /**
   * Checkes if the dropdown content is absent
   * and removes a class not to display a dropdown icon
   * @return {boolean} true if the dropdown content is empty
   */
  const getIsDropdownContentEmpty = toggleDropdownButton => {
    const dropdown = getDropdown(toggleDropdownButton)
    const isDropdownWithContent = dropdown.querySelector(dropdownContentSelector)
    if (!isDropdownWithContent) {
      dropdown.classList.remove(dropdownClassName)
      return true
    }
    return false
  }

  /**
   * Toggles a header dropdown on click
   * @param {event} event click event
   */
  const toggleDropdown = event => {
    const dropdown = getDropdown(event.target)
    const logoutButton = dropdown.classList.contains(logoutButtonClassName)
    const isDropdownOpen = dropdown.querySelector(dropdownContentSelector)?.classList.contains(openDropdownClassName)
    closeAllDropdowns()
    if (!isDropdownOpen && (!logoutButton || (logoutButton && !isMobileHeader))) {
      dropdown.setAttribute(ariaExpanded, 'true')
      dropdown.querySelector(dropdownContentSelector).classList.add(openDropdownClassName)
      overlay.classList.add(visibleOverlayClassName)
    }
  }

  /**
   * Closes a header dropdow on click
   * @param {event} event click event
   */
  const closeDropdown = event => {
    const dropdown = getDropdown(event.target)
    dropdown.setAttribute(ariaExpanded, 'false')
    dropdown.querySelector(dropdownContentSelector)?.classList.remove(openDropdownClassName, fixedDropdownClassName)
    overlay.classList.remove(visibleOverlayClassName)
  }

  /**
   * Closes all header dropdowns
   */
  const closeAllDropdowns = () => {
    toggleDropdownButtons.forEach(toggleDropdownButton => {
      const dropdown = getDropdown(toggleDropdownButton)
      if (dropdown) {
        dropdown.setAttribute(ariaExpanded, 'false')
        dropdown.querySelector(dropdownContentSelector)?.classList.remove(openDropdownClassName)
        overlay.classList.remove(visibleOverlayClassName)
      }
    })
  }

  const openToActivePage = () => {
    const activeNavigationItems = [...document.querySelectorAll(activeItemSelector).values()].sort((item1, item2) => (item1.contains(item2) ? -1 : 1))
    activeNavigationItems.pop()
    activeNavigationItems.forEach(item => (item.tagName !== ANCHOR_TAG ? item.click() : null))
  }

  /**
   * On load calculates the header height and sets it as padding top on the main content
   */
  const initCompensateHeaderHeight = () => {
    const main = document.querySelector('main')
    if (main) {
      main.style.paddingTop = window.innerWidth >= breakpointValues.upLg ? `${header.offsetHeight}px` : null
    }
  }
  /**
   * On scroll on navigation toggles classes to show / hide the sticky navigation
   */

  const initStickyNavigation = () => {
    navigationWrapper.addEventListener('scroll', () => {
      const isScrolled = navigationWrapper.scrollTop >= navigationTopPosition
      header.classList.toggle(stickyHeaderClassName, isScrolled)
      navigation.classList.toggle(menuScrolled, isScrolled)
      buttonBack.classList.toggle(menuScrolled, isScrolled)
    })
  }

  const resetNavigationItems = () => {
    navigation
      .querySelectorAll(navigationItemsSelector)
      ?.forEach(selectedItem => selectedItem.classList.remove('cmp-navigation__item--selected', 'cmp-navigation__item--selected-parent'))
    navigation.querySelectorAll('.cmp-navigation__group')?.forEach(group => group.classList.remove('cmp-navigation__group--selected-child'))
    navigation
      .querySelectorAll(`.${navigationTitleClassName}`)
      ?.forEach((navigationTitle, index) => index !== 0 && navigationTitle.classList.remove(navigationTitleClassName))
    navigation.classList.remove('cmp-navigation--selected')
  }

  /**
   * Toggles mobile menu
   */
  const toggleMenu = () => {
    const isMenuOpen = header.classList.contains(openMenuClassName)
    toggleStikyHeader()

    if (isMenuOpen) {
      navigationWrapper.scrollIntoView()
      closeAllDropdowns()
      if (sticky) {
        sticky.style.display = 'block'
        header.style.paddingBottom = 0
      }
    } else {
      resetNavigationItems()
      openToActivePage()
      header.classList.remove(stickyHeaderClassName)
      if (sticky) {
        sticky.style.display = 'none'
      }
    }

    header.classList.toggle(openMenuClassName)
    document.body.classList.toggle(noScrollClassName)
  }

  const handleToggleDropdowns = () => {
    toggleDropdownButtons.forEach(toggleDropdownButton => {
      const isDropdownContentEmpty = getIsDropdownContentEmpty(toggleDropdownButton)
      if (!isDropdownContentEmpty) {
        toggleDropdownButton.addEventListener('click', event => toggleDropdown(event))
        toggleDropdownButton.addEventListener('keyup', event => {
          if (event.key === 'Escape' || event.key === 'Esc') {
            closeAllDropdowns()
          }
        })
      }
    })
  }

  /**
   * initialize
   */
  const init = () => {
    if (!header || !navigationWrapper) {
      return
    }
    window.addEventListener('scroll', () => window.requestAnimationFrame(toggleStikyHeader))
    handleToggleDropdowns()
    if (header) {
      searchItems.forEach(searchItem =>
        searchItem.addEventListener('click', function () {
          const interactionType = searchItem.parentNode.firstChild.nextSibling.textContent
          trackEvent({
            event: 'interaction',
            eventSource: 'aem',
            action: 'click',
            componentName: 'search-results',
            interactionType: interactionType.replaceAll(' ', '-').toLowerCase(),
            title: searchItem.textContent,
            href: searchItem.href,
          })
        }),
      )
    }
    closeDropdownButtons.forEach(closeDropdownButton => {
      closeDropdownButton.addEventListener('click', event => closeDropdown(event))
    })
    overlay.addEventListener('click', closeAllDropdowns)
    toggleMenuButton.addEventListener('click', toggleMenu)

    if (search?.ariaExpanded) {
      search.querySelector('button')?.addEventListener('click', () => {
        const searchInput = header.querySelector('input')
        searchInput?.focus()
      })
    }

    initCompensateHeaderHeight()
    initStickyNavigation()
    initLogoLinkTracking()

    window.addEventListener(
      'resize',
      debounce(() => initCompensateHeaderHeight(), 200),
    )
  }

  return {
    init,
  }
}

export { Header }
