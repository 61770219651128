import { initChatLiveAgentElements } from '@components/chat-live-agent/chat-live-agent'
import { getIsMobileSmall } from '@site/js/utils/breakpoint'
import debounce from '@site/js/utils/debounce'
import { pxToRem } from '@site/js/utils/px-to-rem'
import { getDocumentReferrerHostname, replaceUrlSearchParamValue } from '@site/js/utils/url'

document.querySelectorAll('.cmp-button.cmp-button--sticky').forEach(stickyButton => {
  stickyButton.parentElement.classList.add('button--sticky')
})

document
  .querySelectorAll('.cmp-button.cmp-button--apply-now')
  .forEach(applyNowButton => (applyNowButton.href = replaceUrlSearchParamValue('ref', getDocumentReferrerHostname(), applyNowButton.href)))

const stickyButton = document.querySelector('.cmp-button--sticky')
const fixedButtonClassName = 'cmp-button--fixed'

const setFixedButtonClass = button => {
  if (button && !button.classList.contains(fixedButtonClassName)) {
    button.classList.add(fixedButtonClassName)
  }
}

setFixedButtonClass(stickyButton)

initChatLiveAgentElements()

const setStickyButtonFixedToTheFooter = entry => {
  if (!getIsMobileSmall()) {
    stickyButton.style.top = entry.isIntersecting
      ? pxToRem(entry.target.getBoundingClientRect().top - stickyButton.getBoundingClientRect().height - 15)
      : null
    stickyButton.style.marginTop = entry.isIntersecting ? '0' : null
    stickyButton.style.bottom = entry.isIntersecting ? 'auto' : null
    stickyButton.style.zIndex = entry.isIntersecting ? '4' : null
  }
}

const handleScrollAndResize = entry => {
  if (!stickyButton) {
    return
  }
  setStickyButtonFixedToTheFooter(entry)
}

const debouncedHandleScrollAndResize = debounce(handleScrollAndResize, 1)

const initIntersectionObserverOnScrollAndResize = () => {
  const footerElement = document.querySelector('.cmp-footer')
  const io = new IntersectionObserver(
    (entries, _observer) => {
      ;['scroll', 'resize'].forEach(event => {
        window.addEventListener(event, () => {
          const entry = entries?.[0]

          if (entry) {
            debouncedHandleScrollAndResize(entry)
          }
        })
      })
    },
    { threshold: 0.1 },
  )

  if (footerElement) {
    io?.observe(footerElement)
  }
}

initIntersectionObserverOnScrollAndResize()
