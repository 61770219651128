import { FactsAndFiguresBlock, FactsAndFiguresBlocksObserver } from '@components/facts-and-figures/animation'
import { isMobile } from '@site/js/utils/breakpoint'
import debounce from '@site/js/utils/debounce'

;(function () {
  'use strict'

  function onDocumentReady() {
    const factsAndFiguresContainer = document.querySelectorAll('.cmp-facts-and-figures')

    if (factsAndFiguresContainer) {
      factsAndFiguresContainer.forEach(factsAndFigures => {
        const navigation = factsAndFigures.querySelector('.cmp-facts-and-figures__navigation')
        const previousButton = navigation?.querySelector('.cmp-facts-and-figures__navigation-button--previous')
        const nextButton = navigation?.querySelector('.cmp-facts-and-figures__navigation-button--next')
        const counter = navigation?.querySelector('.cmp-facts-and-figures__navigation-block-counter')
        const blocksContainer = factsAndFigures.querySelector('.cmp-facts-and-figures__blocks')
        const blocks = [...factsAndFigures.querySelectorAll('.cmp-facts-and-figures__block')]

        if (!navigation || !blocksContainer || blocks.length === 0) {
          return
        }

        let currentIndex = 0

        function getTotalSets() {
          const totalBlocks = blocks.length
          const blocksPerView = getBlocksPerView()
          return Math.ceil(totalBlocks / blocksPerView)
        }

        function getBlocksPerView() {
          if (isMobile) {
            return 1
          }
          return factsAndFigures.dataset.blocksPerView ? parseInt(factsAndFigures.dataset.blocksPerView) : 4
        }

        function getBlockWidth() {
          const desktopGap = 24
          const mobileGap = 16
          if (isMobile) {
            return blocks[0].offsetWidth + mobileGap
          }
          return blocks[0].offsetWidth + desktopGap
        }

        function updateCarousel() {
          const offset = getOffset()
          blocksContainer.style.transform = `translateX(${offset}px)`

          const totalSets = getTotalSets()
          previousButton.disabled = currentIndex === 0
          nextButton.disabled = currentIndex >= totalSets - 1

          counter.textContent = `${currentIndex + 1}/${totalSets}`

          const shouldShowButtons = totalSets > 1
          previousButton.style.display = shouldShowButtons ? 'flex' : 'none'
          nextButton.style.display = shouldShowButtons ? 'flex' : 'none'
          counter.style.display = shouldShowButtons ? 'flex' : 'none'
        }

        function getOffset() {
          const blockWidth = getBlockWidth()
          const blocksPerView = getBlocksPerView()
          const firstBlockIndex = currentIndex * blocksPerView
          return -(firstBlockIndex * blockWidth)
        }

        previousButton.addEventListener('click', () => {
          if (currentIndex > 0) {
            currentIndex--
            updateCarousel()
          }
        })

        nextButton.addEventListener('click', () => {
          const totalSets = getTotalSets()
          if (currentIndex < totalSets - 1) {
            currentIndex++
            updateCarousel()
          }
        })

        updateCarousel()
        window.addEventListener('resize', debounce(updateCarousel, 200))
      })
      const factsAndFiguresBlocks = Array.from(document.querySelectorAll('.cmp-facts-and-figures__block-number')).map(element =>
        new FactsAndFiguresBlock(element).getElement(),
      )
      FactsAndFiguresBlocksObserver(factsAndFiguresBlocks)
    }
  }

  if (document.readyState !== 'loading') {
    onDocumentReady()
  } else {
    document.addEventListener('DOMContentLoaded', onDocumentReady)
  }
})()
