import {trackEvent} from "@site/js/utils/tracking";

;(function () {
    'use strict'

    function onDocumentReady() {
        const marqueeContainers = document.querySelectorAll('.marquee-container')

        if (marqueeContainers) {
            marqueeContainers.forEach(marqueeContainer => {
                const marquee = marqueeContainer.querySelector('.cmp-marquee')
                const marqueeButton = marquee.querySelector('.cmp-marquee__button')
                const { title, text, link } = marquee.dataset

                marqueeButton.addEventListener('click', () => {
                    trackEvent({
                        action: 'click',
                        componentName: 'pagestage-banner-cta',
                        interactionType: 'button',
                        text: text,
                        title: title,
                        href: link,
                    })
                })
            })
        }
    }

    if (document.readyState !== 'loading') {
        onDocumentReady()
    } else {
        document.addEventListener('DOMContentLoaded', onDocumentReady)
    }
})()
