import { trackEvent } from '@site/js/utils/tracking'

const horizontalTeaserButtonLinks = document.querySelectorAll('.cmp-horizontalteaser-v2 .cmp-horizontalteaser-v2__content--link-button')

if (horizontalTeaserButtonLinks) {
  Array.from(horizontalTeaserButtonLinks).forEach((link: HTMLAnchorElement) =>
    link.addEventListener('click', () => {
      trackEvent({
        event: 'interaction',
        eventSource: 'aem',
        action: 'click',
        componentName: 'horizontal-teaser',
        interactionType: 'link',
        href: link.href,
        title: link.innerText,
      })
    }),
  )
}
