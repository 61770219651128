import './ModalContainer.scss'

import { IframeMessageType } from '@site/js/utils/iframe'
import { trackEvent } from '@site/js/utils/tracking'
import { useEffect, useRef, useState } from 'react'

import { cls } from '../utils/classname'

type ModalContainerProps = {
  title?: string
  iframesrc?: string
  size?: string
}

const isSecureMessageEvent = (event: MessageEvent) => event.origin === window.origin

const onMessage = (event: MessageEvent) => {
  if (isSecureMessageEvent(event) && event.data.type === IframeMessageType.IframeEventTracking) {
    trackEvent(event.data.eventData)
  }
}

export default function ModalContainer({ title = '', iframesrc = '', size = 'medium' }: ModalContainerProps) {
  const [isOpen, setIsOpen] = useState(Boolean(iframesrc))
  const dialogElement = useRef(null)

  const closeModal = () => {
    dialogElement?.current?.close()
    document.body.classList.remove('no-scroll')
    setIsOpen(false)
    document.querySelector('.cmp-modal-container').remove()
  }

  useEffect(() => {
    if (isOpen) {
      dialogElement?.current?.showModal()
      document.body.classList.add('no-scroll')
    }
  }, [isOpen])

  useEffect(() => {
    window.addEventListener('message', onMessage)
    return () => window.removeEventListener('message', onMessage)
  }, [])

  return (
    isOpen && (
      <dialog
        ref={dialogElement}
        className={cls({
          'cmp-modal-container-content': true,
          [`cmp-modal-container-content--${size}`]: true,
          'cmp-modal-container--content-loaded': isOpen,
          'cmp-modal-container--content-not-loaded': !isOpen,
        })}
      >
        <header className="cmp-modal-container-content__header">
          {title && <h4 className="cmp-modal-container-content__title">{title}</h4>}
          <button className="cmp-modal-container-content__close-button" title="Close modal dialog" onClick={closeModal} />
        </header>
        <div className="cmp-modal-container-content--wrapper">
          <iframe className="cmp-modal-container-content--iframe" src={iframesrc}></iframe>
        </div>
      </dialog>
    )
  )
}
