/* eslint-disable */

const INITIALISED_CLASSNAME = 'cmp-facts-and-figures__block-number--initialised'
const NO_ANIMATION_CLASSNAME = 'cmp-facts-and-figures__block-number--no-animation'
const COMPLETED_CLASSNAME = 'cmp-facts-and-figures__block-number--completed'
const COUNT_SELECTOR = '.cmp-facts-and-figures__block-number__count'
const FRAME_RATE = 60 /* Frames Per Second, typical for RequestAnimationFrame */

export class FactsAndFiguresBlock {
    constructor(element) {
        this._element = element
        this.count = this._element.querySelector(COUNT_SELECTOR)
        this.config = Object.assign({}, JSON.parse(this._element.dataset.config))
        this.frames = 0
        this.frameAdvance = 0
        this.countValue = 0
        if (isNaN(this.config.endNumber) || isNaN(this.config.startNumber) || isNaN(this.config.duration)) return

        this.initialise()
    }

    initialise() {
        if (this.config.startNumber !== this.config.endNumber) {
            this.countValue = this.config.startNumber
            this.displayCount()
        }

        this.frames = Math.floor(FRAME_RATE * this.config.duration)
        this.frameAdvance = (this.config.endNumber - this.config.startNumber) / this.frames
        this._element.modelInstance = this
        this._element.classList.add(INITIALISED_CLASSNAME)
    }

    displayCount() {
        this.count.textContent = `${Math.round(this.countValue)}`
    }

    getElement() {
        return this._element
    }

    countUp() {
        if (this.countValue >= this.config.endNumber) return
        if (Math.round(this.countValue + this.frameAdvance) >= this.config.endNumber) {
            this.countValue = this.config.endNumber
            this.displayCount()
            this._element.classList.add(COMPLETED_CLASSNAME)
            return
        }

        this.countValue += this.frameAdvance
        this.displayCount()
        window.requestAnimationFrame(() => this.countUp())
    }

    startCount() {
        window.requestAnimationFrame(() => this.countUp())
    }

    resetCount() {
        if (this.countValue === this.config.endNumber) {
            this._element.classList.remove(COMPLETED_CLASSNAME, NO_ANIMATION_CLASSNAME)
            this.countValue = this.config.startNumber
            this.initialise()
        }
    }
}

export const FactsAndFiguresBlocksObserver = (components) => {
    if (!components || components.length <= 0) return
    if ('IntersectionObserver' in window) {
        const factsAndFiguresBlocksObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    const number = entry.target
                    if (entry.isIntersecting) {
                        if (!number.classList.contains(COMPLETED_CLASSNAME) && !number.classList.contains(NO_ANIMATION_CLASSNAME)) {
                            number.modelInstance.startCount()
                        }
                    } else {
                        number.modelInstance.resetCount()
                    }
                })
            },
            { threshold: 0.9 }
        )

        components.forEach(component => factsAndFiguresBlocksObserver.observe(component))
    }
}

/* eslint-enable */
