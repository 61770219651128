/* eslint-disable max-lines */
import {isMobileHeader} from '@site/js/utils/breakpoint'

const ARIA_LEVEL = 'aria-level'

const className = {
  group: 'cmp-navigation__group',
  itemsLevel0: 'cmp-navigation__item--level-0',
  itemsLevel1: 'cmp-navigation__item--level-1',
  expandedNavigation: 'cmp-navigation--expanded',
  selectedNavigation: 'cmp-navigation--selected',
  selectedParentGroup: 'cmp-navigation__group--selected-parent',
  selectedParentItem: 'cmp-navigation__item--selected-parent',
  selectedItem: 'cmp-navigation__item--selected',
  expandedItem: 'cmp-navigation__item--expanded',
  selectedChildGroup: 'cmp-navigation__group--selected-child',
  title: 'cmp-navigation__title',
  noJs: 'no-js',
  activeItem: 'cmp-navigation__item--active',
}

const selector = {
  itemsLevel1: '.cmp-navigation__item--level-1',
  itemsLevel2: '.cmp-navigation__item--level-2',
  backButtonText: '.cmp-navigation__back-button-text',
  itemLink: '.cmp-navigation__item-link',
  group: `.${className.group}`,
}

/**
 * Navigation component
 */
export const Navigation = () => {
  const navigation = document.querySelector('.cmp-navigation')

  let currentMobileItem

  /**
   * Expands or collapses desktop navigation on click
   * @param {event} event click event
   */
  const toggleNavigationItem = event => {
    const clickedNavigationItem = event.target.closest(`.${className.itemsLevel0}`)
    const hasNavigationItemContent = clickedNavigationItem && clickedNavigationItem.querySelector('.cmp-navigation__group')
    document.querySelectorAll(`.${className.itemsLevel0}`).forEach(item => {
      if (item === clickedNavigationItem && hasNavigationItemContent) {
        item.classList.toggle(className.expandedItem)
        if (!isMobileHeader) {
          item.classList.toggle(className.selectedItem)
        }
      } else {
        item.classList.remove(className.expandedItem)
        if (!isMobileHeader) {
          item.classList.remove(className.selectedItem)
        }
      }
    })
    const isNavigationExpanded = navigation.querySelector(`.${className.expandedItem}`)
    navigation.classList.toggle(className.expandedNavigation, isNavigationExpanded)
  }

  const getParentMobileItem = item => {
    if (!item?.getAttribute(ARIA_LEVEL)) {
      return null
    } else if (item?.getAttribute(ARIA_LEVEL) === '1' || item?.getAttribute(ARIA_LEVEL) === '2') {
      return currentMobileItem.closest('.' + className.itemsLevel0)
    } else {
      console.error('Error: Unexpected case in getParentMobileItem for item:', item)
    }
  }

  /**
   * Changes text of the mobile menu 'Back' button when navigating forward/backwards
   */
  const updateBackButtonTitle = () => {
    let backBtnLabel

    if (!currentMobileItem) {
      backBtnLabel = navigation.querySelector(`.${className.title}`).innerText
    } else {
      const parentMobileItem = getParentMobileItem(currentMobileItem)
      if (!parentMobileItem) {
        backBtnLabel = navigation.querySelector(`.${className.title}`).innerText
      } else {
        backBtnLabel = parentMobileItem.querySelector('.' + className.title).innerText
      }
    }

    navigation.querySelector(selector.backButtonText).innerText = backBtnLabel
  }

  /**
   * On the mobile menu 'Back' button click shows the previous menu level
   */
  const goToPreviousMenuLevel = () => {
    const item = navigation.querySelector(`.${className.selectedItem}`)
    if (!item) {
      return
    }

    const parentItem = item.closest(`.${className.selectedParentItem}`)
    if (parentItem) {
      parentItem.classList.remove(className.selectedParentItem)
      parentItem.classList.add(className.selectedItem)
    } else {
      navigation.classList.remove(className.selectedNavigation)
    }

    const parentGroup = item.closest(selector.group)
    parentGroup.classList.remove(className.selectedParentGroup)
    parentGroup.classList.add(className.selectedChildGroup)

    item.classList.remove(className.selectedItem)

    const childGroup = item.querySelector(selector.group)
    if (childGroup) {
      childGroup.classList.remove(className.selectedChildGroup)
    }

    item.querySelector(selector.itemLink)?.classList.remove(className.title)
    updateBackButtonTitle()
  }

  const identifyChildrenOfCurrentItem = parentItem => {
    const parentItemAriaLevel = Number(parentItem.getAttribute(ARIA_LEVEL) || 0)
    const allItems = document.querySelectorAll('.cmp-navigation__content .cmp-navigation__item')
    let currentItemAriaLevel
    let arrivedToParent = false
    let arrivedToNextParent = false

    for (let i = 0; i < allItems.length; i++) {
      currentItemAriaLevel = Number(allItems[i].getAttribute(ARIA_LEVEL) || 0)

      if (allItems[i] === parentItem) {
        arrivedToParent = true
      }

      if (arrivedToParent && !arrivedToNextParent && allItems[i] !== parentItem && currentItemAriaLevel === parentItemAriaLevel) {
        arrivedToNextParent = true
      }

      if (arrivedToParent && !arrivedToNextParent && currentItemAriaLevel === parentItemAriaLevel + 1) {
        allItems[i].classList.add('cmp-navigation__item--child-of-current-item')
        allItems[i].classList.remove('cmp-navigation__item--discarded')
      } else {
        allItems[i].classList.remove('cmp-navigation__item--child-of-current-item')
        allItems[i].classList.add('cmp-navigation__item--discarded')
      }
    }
  }

  /**
   * On the mobile menu item click shows the next menu level
   * @param {event} event event of menu item click
   */
  const goToNextMenuLevel = event => {
    const item = event.target
    // Submenu title is not clickable
    if (item?.classList.contains(className.selectedItem)) {
      return
    }

    const childGroup = item.querySelector(selector.group)
    if (childGroup) {
      // Clicked item is L0 - TODO: refactor to make logic self-explainatory
      childGroup.classList.add(className.selectedChildGroup)

      navigation.classList.add(className.selectedNavigation)

      const parentGroup = item.closest(selector.group)
      parentGroup?.classList.add(className.selectedParentGroup)
      parentGroup?.classList.remove(className.selectedChildGroup)
    } else {
      // Clicked item is L1 - TODO: refactor to make logic self-explainatory
      const parentItem = item.closest(`.${className.selectedItem}`)
      parentItem?.classList.remove(className.selectedItem)
      parentItem?.classList.add(className.selectedParentItem)
    }

    item.classList.add(className.selectedItem)

    if (item.querySelector(selector.itemLink)) {
      item.querySelector(selector.itemLink).classList.add(className.title)
    }

    updateBackButtonTitle()
  }

  /**
   * handles nav item clicks
   * @param {event} event
   */
  const handleNavItemClick = event => {
    sessionStorage.removeItem('listView')
    // Desktop
    toggleNavigationItem(event)
    // Mobile
    if (event.target.classList.contains(className.itemsLevel0)) {
      // remove selection of a sibling mobile menu item
      goToPreviousMenuLevel()
    }
    goToNextMenuLevel(event)
  }

  const addClickHandler = elements => {
    elements.forEach(element => {
      element.addEventListener('click', e => {
        e.stopPropagation()
      })
    })
  }

  const initializeMobileLevelOneItems = () => {
    const levelOneItemsAnchors = document.querySelectorAll(`${selector.itemsLevel1} a`)
    addClickHandler(levelOneItemsAnchors)
  }

  const handleMddTeasers = () => {
    const mdd = document.querySelectorAll(`.mddpromotion`)
    addClickHandler(mdd)
  }

  const initMobileNavigation = () => {
    const navigationItems = document.querySelectorAll('.cmp-navigation__item')

    navigation.querySelector('.cmp-navigation__back-button').addEventListener('click', () => {
      currentMobileItem = getParentMobileItem(currentMobileItem)
      goToPreviousMenuLevel()
      if (currentMobileItem) {
        identifyChildrenOfCurrentItem(currentMobileItem)
      }
    })

    handleMddTeasers()
    initializeMobileLevelOneItems()

    navigationItems.forEach(item => {
      item.addEventListener('click', e => {
        e.stopPropagation()
        currentMobileItem = e.target
        if (item.getAttribute(ARIA_LEVEL) !== '2') {
          goToNextMenuLevel(e)
          identifyChildrenOfCurrentItem(e.target)
        }
      })
    })
  }

  /**
   * initialize
   */
  const init = () => {
    if (!navigation) {
      return
    }

    navigation.classList.remove(className.noJs)

    const levelOneItems = navigation.querySelectorAll(`${selector.itemsLevel1}`)

    if (isMobileHeader) {
      initMobileNavigation()
      levelOneItems.forEach(item => {
        const nextSiblingsAnchor = item.nextElementSibling?.tagName.toLowerCase()
        if (nextSiblingsAnchor && nextSiblingsAnchor === 'a') {
          item.classList.add('cmp-navigation__item--has-children')
        }
      })
    } else {
      navigation.querySelectorAll('.cmp-navigation__item').forEach(item => {
        if (item.querySelectorAll('.u-no-link').length === 0) {
          item.addEventListener('click', handleNavItemClick)
        }
      })

      document.addEventListener('click', event => {
        if (!navigation.contains(event.target)) {
          toggleNavigationItem(event)
        }
      })
    }
  }

  return { init }
}
